import React, { Component } from "react";
import toast from 'react-hot-toast';
import TjdocsApi from '../api/API';

import Authentication from "../Authentication";
import { CadastrarRelease } from "./CadastrarRelease";
import { EditarRelease } from "./EditarRelease";

import Modal from "react-bootstrap/Modal";


export class ListaRelease extends Component {
  constructor(props) {
    super(props);
    this.state = { modal: this.props.exibeListaRelease, releaseSelecionado: null, validated: false, exibeCadastraRelease: false, exibeEditarRelease: false };
    this.handleClose = this.handleClose.bind(this);
    this.handleShow = this.handleShow.bind(this);
    this.handleFile = this.handleFile.bind(this);
    this.usuario = Authentication.getUsuario();
    this.isLogin = Authentication.isLogin();

  }

  componentWillMount() {

    TjdocsApi.listaRelease().catch(error => toast.error(`Erro ao tentar consultar os domínios: ${error}`));
  }


  excluirRelease(e, id) {
    e.preventDefault();
    var confirmacao = window.confirm("Deseja realmente excluir este release?");
    if (confirmacao == true) {
      TjdocsApi.excluiRelease(id).catch(error => toast.error(`Erro ao tentar excluir o release: ${error}`));
      toast.success("Release excluído com sucesso.");
      this.handleClose();
    }

  }

  downloadRelease(e, id, nomeArquivo) {
    e.preventDefault();
    TjdocsApi.getReleaseURL(id);

  }

  handleClose() {
    //this.setState({ modal: false });
    this.props.closeListaRelease();
  }

  handleShow(e) {

    TjdocsApi.listaRelease().then(releases => {
      //console.log(releases);
    }
    ).catch(error => toast.error(`Erro ao tentar consultar os domínios: ${error}`));

    this.setState({ validated: false });
    this.setState({ modal: true });

  }

  handleFile(e) {
    e.preventDefault();
    let file = e.target.files[0];
    this.setState({ arquivo: file });
  }

  handleChange(event) {
    var arquivos = this.state.arquivosVinculados.map((i) => ({ id: null, documentoVinculado: i.documentoVinculado, tipo: event.target.value }))
    this.setState({ arquivosVinculados: arquivos });
  }

  handleSelectChangeArquivos = (data) => {
    var arquivos = data.map((i) => ({ documentoVinculado: { id: i.value.split('/')[2] } }))
    this.setState({ arquivosVinculados: arquivos });
    //console.log(arquivos);
  }

  timeOutInputPesquisaArquivosUpload = setTimeout(() => { }, 100);

  loadOptions = (inputPesquisaNav, callback) => {
    if (inputPesquisaNav.length >= 0) {
      callback(this.state.etiquetas.map((i) => ({ label: i.titulo, value: i.id, color: i.cor })));
    }
  }

  onChangeFunc = (optionSelected) => {
    if (document.querySelector(".loading")) document.querySelector(".loading").style.display = "block";
    window.location.href = optionSelected.value;
  }



  render() {

    return (
      <>

        <CadastrarRelease exibeCadastraRelease={this.props.exibeCadastraRelease} handleCadastraRelease={this.props.handleCadastraRelease} />
        <EditarRelease exibeEditarRelease={this.props.exibeEditarRelease} handleEditarRelease={this.props.handleEditarRelease} releaseSelecionado={this.props.releaseSelecionado} />
        <Modal show={this.props.exibeListaRelease} onHide={this.handleClose} autoFocus={false} dialogClassName={"largeModal"}  >
          <Modal.Header style={{ backgroundColor: "#004A80" }} >
            <Modal.Title style={{ color: "#ffffff", height: "36px", width: "90%" }}  >
              <div style={{ fontWeight: "bold", fontSize: "20px", float: "left" }} className="span12 centered-text"> Releases - TJ<span style={{ fontWeight: "bold", fontSize: "20px", color: "#0FBDD2" }} >DOCS</span> </div>
              <div style={{ float: "right", width: "15px", marginLeft: "10px", cursor: "pointer" }} onClick={(e) => { this.handleClose(e); this.props.handleCadastraRelease() }} className={this.isLogin && (this.usuario.authorities && this.usuario.authorities.includes('ADMINISTRADOR')) ? "" : "d-none "} >Cadastrar Release</div>
              <div style={{ float: "right" }} ><i style={{ fontSize: "30px", marginTop: "5px" }} className={this.isLogin && (this.usuario.authorities && this.usuario.authorities.includes('ADMINISTRADOR')) ? "fa fa-plus" : "d-none "} ></i></div>

            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Último commit: 17/12/2024 19:14
              {this.props.listaRelease.map((release) => {
                return (<>

                  <div style={{ marginTop: "20px", borderBottom: "solid 1px #ccc", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", padding: "5px" }}>
                    <div style={{ fontWeight: "bold", fontSize: "24px", color: "#0FBDD2" }} >{release.numVersao} - {release.nomeVersao} ({release.dataRelease})</div>
                    <div>{release.descricao}</div>
                    <div style={{ fontWeight: "bold", fontSize: "24px", color: "#0FBDD2", marginTop: "36px" }} >Documento de Apresentação</div>
                    <div style={{ width: "100%", backgroundColor: "#004A80", color: "#ffffff", float: "left", height: "50px", padding: "5px", paddingTop: "15px", fontWeight: "bold", fontSize: "15px" }} >
                      <div style={{ width: "10%", float: "left" }}>
                        #
                      </div>
                      <div style={{ width: "80%", float: "left" }}>
                        Título
                      </div>
                      <div style={{ width: "10%", float: "left" }}>
                        Download
                      </div>
                    </div>
                    <div style={{ width: "100%", float: "left", height: "50px", padding: "5px", paddingTop: "15px" }} >
                      <div style={{ width: "10%", float: "left" }}>1</div>
                      <div style={{ width: "80%", float: "left" }}>{release.tituloRelease}</div>
                      <div style={{ width: "10%", float: "left", color: "#004A80", textDecoration: "underline", cursor: "pointer" }} onClick={(e) => { this.downloadRelease(e, release.idDocumentoRelease, release.tituloRelease) }} >Baixar</div>
                    </div>
                    <div style={{ fontSize: "20px", textAlign: "right" }} className={this.isLogin && (this.usuario.authorities && this.usuario.authorities.includes('ADMINISTRADOR')) ? "" : "d-none "} >
                      <i class="fa fa-pen" onClick={(e) => { this.handleClose(e); this.props.handleEditarRelease(e, release) }} style={{ cursor: "pointer" }} ></i>&nbsp;

                      <i class="fa fa-trash" onClick={(e) => this.excluirRelease(e, release.id)} style={{ cursor: "pointer" }} ></i>
                    </div>
                  </div>

                </>)
              })}



            </div>
            <Modal.Footer>
              <div>
                <div style={{ padding: "10px", color: "#004A80", border: "solid 1px #004A80", fontWeight: "bold", width: "80px", textAlign: "center", cursor: "pointer" }} onClick={(e) => { this.handleClose(); }} >Voltar</div>
              </div>
            </Modal.Footer>

          </Modal.Body>

        </Modal>

      </>
    )
  }
};

